module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sam Loyd's Blog","short_name":"Sam Loyd's Blog","start_url":"/","background_color":"#ffffff","theme_color":"#2D3748","display":"standalone","icon":"src/images/sl_app_icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
